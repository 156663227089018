import React, { useEffect, useRef } from "react"
import { useWindowSize } from "../hooks/windowsize"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Icon from "@mdi/react"
import { mdiStar } from "@mdi/js"
import Styles from "../styles/modules/reviews.module.scss"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default ({ data }) => {
  const { width, height } = useWindowSize()
  const sticky = useRef(null)
  const pin = useRef(null)
  const carousel = useRef(null)
  const items = useRef([])

  const timeline = useRef(
    gsap.timeline({
      paused: true,
    })
  )

  useEffect(() => {
    if (height >= 768) {
      const scrollWidth = carousel.current.scrollWidth
      timeline.current.to(sticky.current, {
        scrollTrigger: {
          trigger: sticky.current,
          start: "top top",
          end: "+=" + height * data.length,
          pin: pin.current,
          scrub: 0.5,
          onUpdate: ({ progress }) => {
            // Move container
            const trans = -1 * progress * (scrollWidth + width / 5 - width)
            gsap.to(carousel.current, {
              x: trans + "px",
            })

            // Rotate Cards
            items.current.forEach((v, i) => {
              const { left } = v.getBoundingClientRect()
              gsap.to(v, {
                rotate:
                  ((i % 2 === 0 ? -1 : 1) * 5 * (width - left)) / width + "deg",
              })
            })
          },
        },
      })
    }

    const tm = timeline.current
    const ca = carousel.current
    const slides = items.current
    return () => {
      tm.kill()
      tm.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })
      gsap.set(ca, { clearProps: "all" })
      gsap.set(slides, { clearProps: "all" })
    }
  }, [height, width, data.length])

  return (
    <div ref={sticky}>
      <div ref={pin} className={Styles.pin}>
        <div ref={carousel} className={Styles.reviews}>
          {data.map((item, index) => {
            const review = item.node.frontmatter
            return (
              <div
                key={index}
                ref={ref =>
                  ref && !items.current.includes(ref) && items.current.push(ref)
                }
                className={Styles.review}
              >
                <div className="text--sm">{review.reviewer}</div>
                <div className="text--sm">{review.reviewer_spec}</div>
                <div className="text--md sp-mt-4">“{review.quote}”</div>
                <div className="sp-mt-4">
                  {new Array(review.stars).fill(undefined).map((v, i) => (
                    <Icon key={i} path={mdiStar} color="#8d8888" size={1} />
                  ))}
                </div>
                <div className="text--sm sp-mt-7">
                  <div className="sp-mb-2">{review.title}</div>
                  <div className="color--gray2">{review.content}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
