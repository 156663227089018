import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CanvasGrid from "../components/canvas-grid"
import Reviews from "../components/reviews"
import Button from "../components/button"
import Icon from "@mdi/react"
import { mdiEmailOutline } from "@mdi/js"

const ReviewsPage = ({ data }) => {
  const { t } = useTranslation(["translation", "reviews"])
  return (
    <Layout>
      <SEO
        title={t("reviews:metadata.title")}
        description={t("reviews:metadata.desc")}
        image="reviews.jpg"
      />
      <CanvasGrid class="canvas-grid--dark" theme="dark" />
      <Reviews data={data.reviews.edges} />
      <div className="container-fluid sp-pt-9 sp-pb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">
              {t("reviews:beginYourExperience.title")}
            </div>
            <div
              className="content text--lg sp-mb-7"
              dangerouslySetInnerHTML={{
                __html: t("reviews:beginYourExperience.content"),
              }}
            ></div>
            <a
              className="button button--dark button--icon sp-ml-2 sp-mr-2 sp-mb-4"
              href="mailto: hello@thehighloft.com"
            >
              hello@thehighloft.com
              <span className="icon">
                <Icon path={mdiEmailOutline} />
              </span>
            </a>
            <Button
              className="button button--dark button--icon sp-ml-2 sp-mr-2 sp-mb-4"
              to="/book"
            >
              {t("translation:bookNow")}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const reviewsFrontmatterFragment = graphql`
  fragment ReviewsFrontmatterFragment on MarkdownRemark {
    frontmatter {
      reviewer
      reviewer_spec
      quote
      stars
      title
      content
    }
  }
`

export const pageQuery = graphql`
  query ReviewsPageQuery($language: String) {
    reviews: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/reviews/" }
        frontmatter: { lang: { eq: $language } }
      }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          ...ReviewsFrontmatterFragment
        }
      }
    }
  }
`

export default ReviewsPage
